<template>
  <!-- Testimonials -->
  <div class="container-fluid">
    <div class="row text-center">
      <div class="container">
        <div class="col-md-12">
          <div
            id="carouselExampleControls"
            class="carousel slide carousel-fade testimonials pt-4"
            data-bs-ride="carousel"
          >
            <div class="col-md-8 mx-auto">
              <h2 class="text-green text-center">What our clients say</h2>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <blockquote>
                    <q
                      >New beginnings and new experiences. If that is what you
                      want then nothing beats Amchara. From the beautiful
                      setting in the gorgeous English countryside in a stunning
                      house dating back to the 1600's, to the phenomenal people
                      taking care of every little detail. Everyone was SUPER
                      lovely and make you feel so welcome. The food and juices
                      are so delicious and fresh everyday. Evenings are super
                      cosy by fire watching movies loafing on big comfy sofas.
                      Will 100% go back!!! Thank you Amchara.</q
                    >
                    <cite>
                      Ali, Amchara Somerset,
                      <time datetime="2019-03">March 2019</time>
                    </cite>
                  </blockquote>
                </div>
                <div class="carousel-item">
                  <blockquote>
                    <q
                      >Went to have a real break and get away from everything,
                      it was fantastic - calm, healthy food (delicious) and
                      wonderful walks and yoga. Very comfortable and I feel SO
                      much better after only one week - thank you Amchara -
                      really hope I will be able to come again.</q
                    >
                    <cite>
                      Garbo, Amchara Gozo,
                      <time datetime="2019-01">January 2019</time>
                    </cite>
                  </blockquote>
                </div>
                <div class="carousel-item">
                  <blockquote>
                    <q
                      >Love the place, this is my third visit at Amchara. It is
                      food for the soul and can be an amazing life-changing
                      experience for those who want to learn about how to
                      improve their wellbeing and health.</q
                    >
                    <cite>
                      Karen, Amchara Somerset,
                      <time datetime="2019-02">February 2019</time>
                    </cite>
                  </blockquote>
                </div>
                <div class="carousel-item">
                  <blockquote>
                    <q
                      >After a consultation with Alex the naturopath, I decided
                      to do a juice fast, which was perfect for me. I never felt
                      hungry: on the contrary, the juices were really
                      energising. At each juice time, all the naturopaths were
                      on hand to deal with any questions. I also undertook some
                      treatments: reiki and a signature facial. Liz, the reiki
                      specialist, has truly magical and healing hands. I am very
                      grateful to her. Rose gave the most delightful facial.
                      Gozo itself is easy to explore from the retreat and has
                      lots of interesting sites, including the magnificent
                      citadel. The retreat organises walks, but it is fine to
                      explore by oneself too.</q
                    >
                    <cite>
                      Molly, Amchara Gozo,
                      <time datetime="2019-01">January 2019</time>
                    </cite>
                  </blockquote>
                </div>
                <div class="carousel-item">
                  <blockquote>
                    <q
                      >My daughter has health challenges and doing a detox makes
                      her feel more empowered with her health. The staff were
                      amazing, helpful, caring and kind to us. Met some great
                      people there and hearing other people’s health challenges
                      made us feel we’re not so alone. Does us both the world of
                      good, gives us a break from everyday living and a reboot.
                      I would definitely recommend to anyone.</q
                    >
                    <cite>
                      Brenda, Amchara Somerset,
                      <time datetime="2019-03">March 2019</time>
                    </cite>
                  </blockquote>
                </div>
                <div class="carousel-item">
                  <blockquote>
                    <q
                      >The best decision I ever made to go to Amchara for my
                      birthday. The staff and fellow guests were so lovely.
                      Great facilities, good selection of juices and vegan food.
                      After the initial detox I started to feel full of energy
                      and my cravings had gone.</q
                    >
                    <cite>
                      Rita, Amchara Gozo,
                      <time datetime="2019-02">February 2019</time>
                    </cite>
                  </blockquote>
                </div>
              </div>
              <!-- Carousel Inner End -->
            </div>
            <!-- col-md-8 End -->

            <a class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true">
                <i
                  class="fa fa-chevron-circle-left fa-4x mt-5 mt-md-2 d-none d-md-block"
                  style="color: #8bc34a"
                ></i>
                <i
                  class="fa fa-chevron-circle-left fa-2x mt-5 mt-md-2 d-block d-md-none"
                  style="color: #8bc34a"
                ></i>
              </span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true">
                <i
                  class="fa fa-chevron-circle-right fa-4x mt-5 mt-md-2 d-none d-md-block"
                  style="color: #8bc34a"
                ></i>
                <i
                  class="fa fa-chevron-circle-right fa-2x mt-5 mt-md-2 d-block d-md-none"
                  style="color: #8bc34a"
                ></i>
              </span>
              <span class="sr-only">Next</span>
            </a>
          </div>
          <!-- Carousel End -->
        </div>
        <!-- col-md-12 -->
      </div>
      <!-- Container End -->
    </div>
    <!-- Row -->
  </div>
  <!-- Conatiner-fluid End -->
</template>

<script>
export default {};
</script>

<style>
</style>
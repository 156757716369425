<template>
  <!-- Newsletter Sign Up -->
  <div class="container-fluid py-4 mt-3 bg-primary newsletter-area-backg">
    <div id="newsletter-signup-cta">
      <div class="row text-center">
        <div class="col-md-12 newsletter-area">
          <!--<img
            src="@/assets/images/logos/generic/darkgreen_newsletter_icon.svg"
            style="width: 100px"
            alt
          />
          <h3 class="text-white">
            Like what you've read? Want more of the same?
          </h3>-->
          <h5 class="text-white">
            Sign up for our newsletter to receive updates, news, recipes, offers
            and more...
          </h5>
        </div>
      </div>
      <div class="row text-center pt-2">
        <div class="col-md-8 mx-auto">
          <form @submit.prevent class="flex flex-wrap justify-center">
            <div class="row">
              <div class="col-12 col-md-3 my-3">
                <input
                  type="text"
                  v-model="form.first_name"
                  placeholder="First Name"
                  class="form-control w-100"
                  required
                />
              </div>
              <div class="col-12 col-md-3 my-3">
                <input
                  type="text"
                  v-model="form.last_name"
                  placeholder="Last Name"
                  class="form-control w-100"
                  required
                />
              </div>
              <div class="col-12 col-md-3 my-3">
                <input
                  type="email"
                  v-model="form.email"
                  placeholder="Email"
                  class="form-control w-100"
                  required
                />
              </div>
              <div class="col-12 col-md-3 my-3">
                <vue-recaptcha
                  v-show="canSubmit"
                  class="mb-4"
                  sitekey="6LdCGVMUAAAAAAI5h_FtbkmybCkOPtLGQJLWhZxT"
                  :loadRecaptchaScript="true"
                  @verify="recaptcha"
                ></vue-recaptcha>

                <button
                  class="btn newsletter-btn-green w-100"
                  :disabled="!notBot && !canSubmit"
                  @click="signUp"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="row py-5 text-center"
      style="display: none"
      id="newsletter-signup-thanks"
    >
      <div class="col">
        <h4 class="text-white">
          Thanks for signing up to the Amchara Newsletter
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: ""
      },
      canSubmit: false,
      notBot: false
    };
  },
  methods: {
    recaptcha(r) {
      this.notBot = true;
    },
    signUp() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/newsletter-sign-up", this.form)
        .then(({ data }) => {
          $("#newsletter-signup-cta").hide();
          $("#newsletter-signup-thanks").show();
          this.$router.push("/newsletter-confirmation");
        });
    }
  },
  mounted() {},
  watch: {
    form: {
      handler: function(val) {
        if (
          val.first_name.length > 2 &&
          val.last_name.length > 2 &&
          val.email.length > 4
        ) {
          this.canSubmit = true;
        } else {
          this.canSubmit = false;
        }
      },
      deep: true
    }
  },
  components: { VueRecaptcha }
};
</script>

<style>
</style>